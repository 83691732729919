<template>
  <section class="p-2">
    <validation-observer ref="fuelCertificateRef" tag="form" @submit.prevent="onSubmit">
      <div class="d-flex justify-content-between mb-2">
        <h2 class="mb-0">{{ $t('service.fuel_certificate.title') }}</h2>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end pb-1 mt-50">
            <b-form-checkbox :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'" :checked="copyFo" name="check-button" switch inline>
              {{ $t('service.order_request.copy_provider') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>

      <b-card class="border mt-4">
        <b-row>
          <b-col v-for="(_, key) in fuelCertificateOption" :key="key" cols="12" md="6">
            <b-form-checkbox v-model="fuelCertificateOption[key]" :disabled="$route.name === 'productShow' || $route.name === 'operatorProductShow'" class="mb-2" @input="product.mainVariant.fuelCertificateOption[key] = $event">
              {{ $t(`service.fuel_certificate.options.${snakeCase(key)}`) }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-card>

      <footer-form-service v-if="$route.name === 'productShow' || $route.name === 'operatorProductShow'" previous next :disabled="saveLoader" @click:next="$emit('next-tab')" @click:previous="$emit('previous-tab')" />
      <footer-form-service v-else previous save save-next :disabled="saveLoader" @click:previous="$emit('previous-tab')" @click:save-next="nextRedirection = true" />
    </validation-observer>
  </section>
</template>

<script>
import { defineComponent, inject, ref } from '@vue/composition-api'

import { snakeCase, omit } from 'lodash'
import FooterFormService from '../component/FooterFormService.vue'

export default defineComponent({
  components: {
    FooterFormService,
  },

  setup(_props, ctx) {
    const { $i18n, toaster } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')

    const fuelCertificateRef = ref(null)

    const saveLoader = ref(false)
    const nextRedirection = ref(false)
    const copyFo = ref(true)

    const fuelCertificateOption = ref({
      aircraftRegistrationOption: false,
      amountInLitersOption: false,
      beginTimeOfFuelingOption: false,
      calculatedNetLitersAt15DegreesCelsiusOption: false,
      certificateNumberOption: false,
      clientOption: false,
      dateOption: false,
      endTimeOfFuelingOption: false,
      fuelDensityOption: false,
      fuelTemperatureOption: false,
      fuelTruckNumberOption: false,
      fuelTypeOption: false,
      fuelingTaxRelevantOrNotOption: false,
      invoiceNumberOption: false,
      paymentTypeOption: false,
      providerOption: false,
    })

    product.value.fetchCertificate(ctx.root.$route.params.service_id, ctx.root.$route.query.vid).then(() => {
      // Assign to keep order front (not order api)
      fuelCertificateOption.value = {
        ...fuelCertificateOption.value,
        ...omit(product.value.mainVariant.fuelCertificateOption, 'id'),
      }
    })

    const onSubmit = () =>
      fuelCertificateRef.value.validate().then(validForm => {
        if (validForm) {
          saveLoader.value = true
          product.value
            .patchCertificate()
            .then(() => {
              if (nextRedirection.value) {
                $emit('next-tab')
                nextRedirection.value = false
              }
            })
            .finally(() => {
              saveLoader.value = false
            })
        } else {
          toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
        }
      })

    return {
      product,
      fuelCertificateRef,
      saveLoader,
      nextRedirection,
      copyFo,
      fuelCertificateOption,
      onSubmit,
      snakeCase,
    }
  },
})
</script>
